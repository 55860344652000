import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import WOW from 'wowjs';
import Scrolltotop from './Components/Scrolltotop';


const About = lazy(() => import('./Pages/About'));
const Blog = lazy(() => import('./Pages/Blog'));
const Gallery = lazy(() => import('./Pages/Gallery'));
const AlbumView = lazy(() => import('./Pages/Gallery/AlbumView'));
const BlogDetials = lazy(() => import('./Pages/Blog/BlogDetials'));
const Career = lazy(() => import('./Pages/Career'));
const Claims = lazy(() => import('./Pages/Claims'));
const ComingSoon = lazy(() => import('./Pages/ComingSoon'));
const ContactUs = lazy(() => import('./Pages/Contactus'));
const Home = lazy(() => import('./Pages/Home'));
const Quotes = lazy(() => import('./Pages/Quotes/Quotes'));
const Login = lazy(() => import('./Pages/Login'));
const Dashboard = lazy(() => import('./Pages/Dashboard'));
const ProductPage = lazy(() => import('./Pages/ProductP'));
const Afentoboa = lazy(() => import('./Pages/ProductList/Afentoboa'));
const MicroEducation = lazy(() => import('./Pages/ProductList/MicroEdu'));
const MicroPension = lazy(() => import('./Pages/ProductList/MicroPen'));
const Bankassurance = lazy(() => import('./Pages/ProductList/Bankassurance'));
const Creditlife = lazy(() => import('./Pages/ProductList/Creditlife'));
const Farewell = lazy(() => import('./Pages/ProductList/Farewell'));
const Funeral = lazy(() => import('./Pages/ProductList/Funeral'));
const Travel = lazy(() => import('./Pages/ProductList/Travel'));
const Group = lazy(() => import('./Pages/ProductList/Group'));
const Keyman = lazy(() => import('./Pages/ProductList/Keyman'));
const Mortgage = lazy(() => import('./Pages/ProductList/Mortgage'));
const LifeGuarantor = lazy(() => import('./Pages/ProductList/LifeGuarantor'));
const Pension = lazy(() => import('./Pages/ProductList/Pension'));
const Boarding = lazy(() => import('./Pages/About/Board'));
const Executives = lazy(() => import('./Pages/About/Executives'));
const FunctionalHead = lazy(() => import('./Pages/About/FunctionalHead'));

// const MakeClaim = lazy(() => import('./Pages/MakeClaim.js'));

function App() {

  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, [])

  return (
    <div >
      <BrowserRouter>
        <Scrolltotop />
        <Suspense fallback={
          <div className="flex items-center justify-center">
            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          </div>
        }>
            <Routes>
              <Route path='/' element={<Home />}/>
              <Route path='/career' element={<Career />} />
              <Route path='/media/blog' element={<Blog />} />
              {/* <Route path='/media/csresg' element={<CsrEsg/>} />*/}
              <Route path='/media/gallery' element={<Gallery/>} />
              <Route path='/media/gallery/album/:albumId' element={<AlbumView/>} />
              <Route path='/blogdetails' element={<BlogDetials/>} />
              <Route path='/about' element={<About />}/>
              <Route path='/board' element={<Boarding/>}/>
              <Route path='/functional' element={<FunctionalHead/>}/>
              <Route path='/executive' element={<Executives/>}/>
              <Route path='/contactus' element={<ContactUs />} />
              <Route path='/quote' element={<Quotes />} />
              <Route path='/claims' element={<Claims/>}/>
              <Route path='/login' element={<Login/>}/>
              <Route path='/product' element={<ProductPage />} />
              <Route path='/product/afentoboa' element={<Afentoboa/>}/>
              <Route path='/product/microedu' element={<MicroEducation/>}/>
              <Route path='/product/micropen' element={<MicroPension/>}/>
              <Route path='/product/bankassurance' element={<Bankassurance/>}/>
              <Route path='/product/creditlife' element={<Creditlife/>}/>
              <Route path='/product/farewell' element={<Farewell/>}/>
              <Route path='/product/funeral' element={<Funeral/>}/>
              <Route path='/product/travel' element={<Travel/>}/>
              <Route path='/product/group' element={<Group/>}/>
              <Route path='/product/keyman' element={<Keyman/>}/>
              <Route path='/product/mortgage' element={<Mortgage/>}/>
              <Route path='/product/educationplan' element={<LifeGuarantor/>}/>
              <Route path='/product/pension' element={<Pension/>}/>
              <Route path='/comingsoon' element={<ComingSoon />}/>

              <Route path='*' element={<Dashboard/>} />
            </Routes>
          </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
